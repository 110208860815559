<template>
  <div>
    <div class="grid w-screen h-screen grid-cols-1">
      <div class="grid w-full h-screen">
        <div class="place-self-center" id="start">
          <div class="title">{{ $t("enter_kiosk_code") }}</div>

          <div class="relative mb-3">
            <input type="text" v-model="key" class="input" autofocus @keydown.enter="send(key)" />

            <div class="absolute text-xs error" v-if="showError">
              {{ $t("kiosk_code_error") }}
            </div>
          </div>

          <div>
            <button class="btn btn-primary" @click="send(key)">
              {{ $t("send_kiosk_code") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Peer from "peerjs";

export default {
  name: "Launch",
  data() {
    return {
      key: "",
      showError: false,
      peer: null,
      conn: null,
      lastPeerId: null,
    };
  },
  created() {
    this.peer = new Peer(null, {
      debug: 2,
    });

    this.peer.on("open", () => {
      console.log("ID: " + this.peer.id);
      console.log("Awaiting connection...");

      this.join(this.$route.params.id);
    });

    this.peer.on("connection", (connection) => {
      console.log("Connected to: " + connection.peer);
    });

    this.peer.on("disconnected", () => {
      console.log("Connection lost. Please reconnect");
      // Workaround for peer.reconnect deleting previous id
      this.peer.id = this.lastPeerId;
      this.peer._lastServerId = this.lastPeerId;
      this.peer.reconnect();
    });

    this.peer.on("close", () => {
      console.log("Connection destroyed");
    });

    this.peer.on("error", (err) => {
      console.log(err);
    });
  },
  methods: {
    join(id) {
      console.log("Joining: " + id);
      // Close old connection
      if (this.conn) {
        this.conn.close();
      }

      // Create connection to destination peer specified in the input field
      this.conn = this.peer.connect(id, {
        reliable: true,
      });

      this.conn.on("open", () => {
        console.log("Connected to: " + this.conn.peer);
      });

      // Handle incoming data (messages only since this is the signal sender)
      this.conn.on("data", (data) => {
        console.log("Got data: " + data);
      });

      this.conn.on("close", () => {
        console.log("Connection closed");
      });
    },

    send(data) {
      if (this.conn) {
        this.conn.send(data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#start {
  .title {
    color: rgba(80, 125, 223, 1);
    text-transform: uppercase;
    @apply text-2xl;
    line-height: 1.25;
  }

  .input {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 6px 12px;
    font-size: 20px;
    width: 100%;
    color: #555;
    @apply outline-none;
  }

  .error {
    color: red;
    padding: 2px;
  }

  .btn {
    @apply text-base;
    border-radius: 8px;
    box-shadow: none;
    color: #fff;
    font-weight: 400;
    text-align: center;
    padding: 6px 12px;
    width: 100%;
  }

  .btn-primary {
    background-color: #507ddf;
    border-color: #507ddf;
  }
}
</style>
